<script lang="ts" setup>
import type { TabItem } from '#ui/types'

interface Props {
  items: (TabItem & { to?: string })[]
  modelValue?: number
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: 0,
})

const emit = defineEmits<{
  'update:modelValue': [index: number]
}>()

const ui = {
  base: '',
  container: 'border-t border-forgd-bgd-600',
  list: {
    base: 'nuxt-ui__tab-list [&>button:nth-child(n+3)]:ml-7 bg-transparent',
    padding: 'p-0',
    height: 'h-12',
    marker: {
      wrapper: 'nuxt-ui__tab-marker absolute top-[40px]! left-[4px] duration-200 ease-out focus:outline-none]',
      base: 'h-1',
      background: 'bg-forgd-primary-300',
      rounded: 'rounded-none',
    },
    tab: {
      base: 'w-auto h-full hover:text-forgd-primary-200',
      font: 'font-bold',
      padding: 'px-5',
      active: 'text-forgd-primary-300',
    },
  },
}

const router = useRouter()
function update(v: number) {
  // check if item has a to
  if (props.items[v].to) {
    // navigate to the to
    return router.push(props.items[v].to)
  }
  emit('update:modelValue', v)
}
</script>

<template>
  <UTabs
    data-ui="UiTabs"
    :model-value="props.modelValue"
    :items="items"
    :ui="ui"
    @update:model-value="update"
  >
    <template
      v-for="item in items.filter(i => i.slot)"
      #[item.slot]="data"
    >
      <slot :name="item.slot" v-bind="data" />
    </template>
  </UTabs>
</template>

<style>
.nuxt-ui__tab-list {
  display: inline-flex;
  width: auto;
  grid-template-columns: none !important;
}

.nuxt-ui__tab-marker {
  top: 110% !important;
}
</style>
